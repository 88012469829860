.dialog-modal{
  
}

.dialog-modal.modal.is-active{
  justify-content: unset;
}

.dialog-modal.modal>.modal-card{
  margin-top:-6rem;
  opacity: 0;
  transition:0.2s all ease-in;
}

.dialog-modal.modal.is-show>.modal-card{
  margin-top:2rem;
  opacity: 1;
  
}

.dialog-modal.modal>.modal-background{
  opacity: 0;
  transition:0.3s all ease-in;
}
.dialog-modal.modal.is-show>.modal-background{
  opacity: 1;
}



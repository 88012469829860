.dialog-modal.modal.is-active {
  justify-content: unset;
}

.dialog-modal.modal > .modal-card {
  opacity: 0;
  margin-top: -6rem;
  transition: all .2s ease-in;
}

.dialog-modal.modal.is-show > .modal-card {
  opacity: 1;
  margin-top: 2rem;
}

.dialog-modal.modal > .modal-background {
  opacity: 0;
  transition: all .3s ease-in;
}

.dialog-modal.modal.is-show > .modal-background {
  opacity: 1;
}

/*# sourceMappingURL=index.79cba9a4.css.map */
